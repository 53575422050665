import { WellPermitCardProps } from "../../types/map/wellSpot/mapHover";

import * as attributes from "../../constants/attributes";

import useAttributeUnit from "../../customHooks/common/useAttributeUnit";
import usePermitPanelViewAction from "../../customHooks/panels/permitsPanel/usePermitPanelViewAction";

import { getFormattedDate, getNumberWithComma } from "../../utils";
import WellInfoText from "../common/WellInfoText";
import {
  FirstProdMonthIcon,
  ProducingFormationIcon,
  TGSOperatorIcon,
  TotalVerticalDepth,
} from "../common/icons/wellLabel";
import PermitNumberIcon from "../common/icons/wellLabel/PermitNumberIcon";
import HoverCard from "./hoverCard/HoverCard";
import HoverCardFooter from "./hoverCard/HoverCardFooter";
import HoverCardHeader from "./hoverCard/HoverCardHeader";

const PermitCard = ({
  open,
  anchorEl,
  wellData,
  isArrowEnabled = true,
  placement,
  offset,
  className = "",
  handleOnHoverAway,
}: WellPermitCardProps) => {
  const { handleOpenPermitPanel, shouldDisablePermitCardButton } =
    usePermitPanelViewAction();
  const { getAttributeUnit } = useAttributeUnit();
  return (
    <HoverCard
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      offset={offset}
      isArrowEnabled={isArrowEnabled}
      handleOnHoverAway={handleOnHoverAway}
      className={className}
    >
      <HoverCardHeader
        RecordType={wellData.RecordType}
        UWI={wellData.UWI}
        WellStatus={wellData.WellStatus}
        WellName={wellData.WellName}
        WellNumber={wellData.WellNumber}
      />
      <div className="well-label-other-info-container">
        <div className="well-label-other-info">
          <div className="well-label-col">
            <WellInfoText
              label={attributes.OPERATOR_NAME.label}
              value={wellData.OperatorName ?? ""}
              icon={<TGSOperatorIcon />}
            />
            <WellInfoText
              label="Permitted Formation"
              value={wellData.ProducingFormation ?? ""}
              icon={<ProducingFormationIcon />}
            />
            <WellInfoText
              // hardcoded for now. change this when the attribute label is already updated
              label="Permit Approved Date"
              value={
                wellData.PermitApprovalDate
                  ? getFormattedDate(wellData.PermitApprovalDate, "MM/DD/YYYY")
                  : ""
              }
              icon={<FirstProdMonthIcon />}
            />
          </div>
          <div className="well-label-col">
            <WellInfoText
              label={attributes.PERMIT_NUMBER.label}
              value={wellData.PermitNumber ?? ""}
              icon={<PermitNumberIcon />}
            />
            <WellInfoText
              label={attributes.PERMIT_TOTAL_DEPTH.label}
              value={
                wellData.ProposedTotalDepth
                  ? `${getNumberWithComma(
                      Math.round(wellData.ProposedTotalDepth)
                    )} ${getAttributeUnit(attributes.PERMIT_TOTAL_DEPTH.key)}`
                  : "N/A"
              }
              icon={<TotalVerticalDepth />}
            />
            <WellInfoText
              label={attributes.PERMIT_TYPE.label}
              value={wellData.PermitType ?? ""}
              icon={<span />}
            />
          </div>
        </div>
        <HoverCardFooter
          isDisabled={shouldDisablePermitCardButton}
          onClickMoreInfo={handleOpenPermitPanel}
        />
      </div>
    </HoverCard>
  );
};

export default PermitCard;
